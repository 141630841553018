import { defineStore } from "pinia";
import { $useFetch } from "~/composables/useFetch";
import { useNuxtApp } from "#app";

export const useInfoStore = defineStore("info", {
	state: () => ({
		userId: null,
		userName: "",
		userLastname: "",
		userEmail: "",
		userLogin: "",
		userPhone: "",
		userPriceType: "",
		userWarehouses: [],
		userBalanceValue: null,
		userBalanceDate: "",
	}),
	actions: {
		async fetchInfo() {
			const { api } = useNuxtApp();

			await $useFetch(api.userInfo, {
				onRequest({ options }) {
					options.method = "get";
				},
				onResponse({ response }) {
					useInfoStore().setInfo(response._data.data);
				},
			});
		},
		setInfo(info) {
			if ("id" in info) this.userId = info.id;
			if ("name" in info) this.userName = info.name;
			if ("lastname" in info) this.userLastname = info.lastname;
			if ("email" in info) this.userEmail = info.email;
			if ("login" in info) this.userLogin = info.login;
			if ("phone" in info) this.userPhone = info.phone;
			if ("price_type" in info) this.userPriceType = info.price_type;
			if ("warehouses" in info) this.userWarehouses = info.warehouses;
			if ("balance" in info) {
				if ("value" in info.balance) {
					this.userBalanceValue = info.balance.value;
				} else {
					this.userBalanceValue = null;
				}
				if ("date_update" in info.balance) {
					this.userBalanceDate = info.balance.date_update;
				} else {
					this.userBalanceDate = "";
				}
			}
		},
		setUserWarehouses(warehouses) {
			this.userWarehouses = warehouses;
		},
		setUserEmail(email) {
			this.userEmail = email;
		},
		setUserId(id) {
			this.userId = id;
		},
		setUserLastname(lastname) {
			this.userLastname = lastname;
		},
		setUserLogin(login) {
			this.userLogin = login;
		},
		setUserName(name) {
			this.userName = name;
		},
		setUserPhone(phone) {
			this.userPhone = phone;
		},
		setUserPriceType(type) {
			this.userPriceType = type;
		},
		setUserBalanceValue(val) {
			this.userBalanceValue = val;
		},
		setUserBalanceDate(date) {
			this.userBalanceDate = date;
		},
	},
	getters: {
		getInfo() {
			return {
				id: this.userId,
				name: this.userName,
				lastname: this.userLastname,
				email: this.userEmail,
				login: this.userLogin,
				phone: this.userPhone,
				price_type: this.userPriceType,
				warehouses: this.userWarehouses,
				balance_value: this.userBalanceValue,
				balance_date: this.userBalanceDate,
			};
		},
		getUserWarehouses() {
			return this.userWarehouses;
		},
		getUserEmail() {
			return this.userEmail;
		},
		getUserId() {
			return this.userId;
		},
		getUserLastname() {
			return this.userLastname;
		},
		getUserLogin() {
			return this.userLogin;
		},
		getUserName() {
			return this.userName;
		},
		getUserPhone() {
			return this.userPhone;
		},
		getUserPriceType() {
			return this.userPriceType;
		},
		getUserBalanceValue() {
			return this.userBalanceValue;
		},
		getUserBalanceDate() {
			return this.userBalanceDate;
		},
	},
});
